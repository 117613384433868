// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-degustation-js": () => import("./../../../src/pages/degustation.js" /* webpackChunkName: "component---src-pages-degustation-js" */),
  "component---src-pages-fetes-js": () => import("./../../../src/pages/fetes.js" /* webpackChunkName: "component---src-pages-fetes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-magasin-js": () => import("./../../../src/pages/magasin.js" /* webpackChunkName: "component---src-pages-magasin-js" */),
  "component---src-pages-traiteur-js": () => import("./../../../src/pages/traiteur.js" /* webpackChunkName: "component---src-pages-traiteur-js" */),
  "component---src-pages-vins-et-spiritueux-js": () => import("./../../../src/pages/vins-et-spiritueux.js" /* webpackChunkName: "component---src-pages-vins-et-spiritueux-js" */)
}

